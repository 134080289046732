import './Footer.css';

function Footer() {
    return (
        <section className="Footer">
            <a
                href="https://www.trucland.org"
                title="Visiter le site de l'écolieu Truc'land"
            >
                <img
                    className="Footer-img"
                    src="bandeau-trucland.png"
                    alt="Truc'land : écolieu associatif et expérimental"
                />
            </a>

            <p className="Footer-about">
                <em>2024 - Truc'land - association à but non-lucratif (loi 1901)</em>
            </p>
        </section>
    );
}

export default Footer;
