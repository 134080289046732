import './Checkout.css';

import { EMAILJS_PUBLIC_KEY, EMAILJS_SERVICE, EMAILJS_TEMPLATE_ORDER_CONFIRMATION } from '../../variables';
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import emailjs from '@emailjs/browser';
import { getTotal } from '../../utils';

function Checkout({
    cart,
}) {
    const [selectedSetting, setSelectedSetting] = useState();
    const [orderStatus, setOrderStatus] = useState();

    useEffect(() => {
        if (cart.length === 0 && selectedSetting) {
            setSelectedSetting(null);
        }
    }, [cart, selectedSetting]);

    const handleSelectSetting = id => {
        if (cart.length === 0) return;
        setSelectedSetting(id);
    };

    const handleSubmit = e => {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);
        const formJson = Object.fromEntries(formData.entries());

        let cartHtml = cart.map(product => 
            `<table bgcolor="">
                <tr>
                    <td class="small" width="90px" style="vertical-align: top; padding-right:18px;">
                        <img src="https://shop.trucland.org/images/products/thumbnails/${product.image}" width="120px" />
                    </td>
                    <td>
                        <h2>
                            <a href="https://shop.trucland.org/#${product.id}" title="Aller sur la page du produit">
                                Confiture de cerises
                            </a>
                        </h2>
                        <p class="">${product.sublabel}
                        <br />${product.date}
                        <br /><em>${product.ingredients}</em></p>
                    </td>
                    <td class="small" width="30px" style="">
                        x${product.quantity}
                    </td>
                    <td width="80px" style="color:#644392;font-weight:700;text-align:right;">
                        ${product.price}
                    </td>
                </tr>
            </table>`
        );

        const data = {
            ...formJson,
            cart: cartHtml,
            total: getTotal(cart),
        }

        emailjs.send(EMAILJS_SERVICE, EMAILJS_TEMPLATE_ORDER_CONFIRMATION, data, EMAILJS_PUBLIC_KEY)
            .then(
                response => {
                    console.log('SUCCESS!', response.status, response.text);
                    setOrderStatus('SUCCESS');
                },
                error => {
                    console.log('ERROR...', error);
                    setOrderStatus('ERROR');
                }
            );
        setSelectedSetting(null);
        setOrderStatus('PENDING');
    };

    return (
        <div className="Checkout">
            <div
                onClick={() => {}}
                className={classNames(
                    "Checkout-item",
                    "Checkout-item--onSite",
                    {"Checkout-item--current": selectedSetting === 0},
                    {"Checkout-item--deactivated": cart.length === 0}
                )}
            >
                <div className="Checkout-item-header">
                    <h5
                        onClick={() => handleSelectSetting(0)}
                        className={classNames(
                            "Checkout-item-title",
                            "Checkout-item-title--onSite",
                            {"Checkout-item-title--current": selectedSetting === 0},
                            {"Checkout-item-title--inactive": selectedSetting === 1},
                            {"Checkout-item-title--deactivated": cart.length === 0}
                        )}
                    >
                        Retrait et paiement sur place
                        <br />
                        <span className="Checkout-item-subtitle">
                            à Sorges (24420), sur rendez-vous
                        </span>
                    </h5>
                </div>

                {selectedSetting === 0 && <>
                    <p className="Checkout-item-description">
                        Laisse nous tes coordonnées et tes dispos, nous te contacterons dès que possible :
                    </p>
                    <form method="post" onSubmit={handleSubmit}>
                        <input
                            type="text"
                            placeholder="Ton nom"
                            className="Checkout-Input"
                            name="name"
                            minLength={2}
                            required
                        />
                        <input
                            type="tel"
                            placeholder="Ton numéro de téléphone"
                            className="Checkout-Input"
                            name="phone"
                            required
                        />
                        <input
                            type="email"
                            placeholder="Ton adresse e-mail"
                            className="Checkout-Input"
                            name="email"
                            required
                        />
                        <textarea
                            placeholder="Ton message : merci d'indiquer tes disponibilités"
                            className="Checkout-Input"
                            name="message"
                            required
                        />
                        <button
                            type="submit"
                            className="Checkout-btn--order Checkout-btn"
                        >
                            Envoyer mon bon de commande
                        </button>
                    </form>
                </>}

                {orderStatus === 'PENDING' &&
                    <p className='Checkout-loader'>Envoi en cours, merci de patienter</p>
                }
            </div>
            <div
                onClick={() => {}}
                className={classNames(
                    "Checkout-item",
                    "Checkout-item--LBC",
                    {"Checkout-item--current": selectedSetting === 1},
                    {"Checkout-item--deactivated": cart.length === 0}
                )}
            >
                <div className="Checkout-item-header">
                    <h5
                        onClick={() => handleSelectSetting(1)}
                        className={classNames(
                            "Checkout-item-title",
                            "Checkout-item-title--LBC",
                            {"Checkout-item-title--current": selectedSetting === 1},
                            {"Checkout-item-title--inactive": selectedSetting === 0},
                            {"Checkout-item-title--deactivated": cart.length === 0}
                        )}
                    >
                        Paiement en ligne sur leboncoin.fr
                        <br />
                        <span className="Checkout-item-subtitle">
                            livraison à domicile ou en point relais
                        </span>
                    </h5>
                </div>

                {selectedSetting === 1 && <>
                    <p className="Checkout-item-description">
                        Retrouve ces articles sur <em>leboncoin.fr</em> et crée un lot pour regrouper les frais de livraison :
                    </p>
                    <ul className="Checkout-item-products">
                        {cart.map(product =>
                            <li className="Checkout-item-product">
                                <a
                                    className="Checkout-item-product-link"
                                    href={product.externalUrl}
                                    title={`Page Leboncoin du produit ${product.label}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <span className="Checkout-item-product-title">
                                        {product.label}
                                    </span>
                                    <br />
                                    <span className="Checkout-item-product-url">
                                        {product.externalUrl.replace('http://www', '')}
                                    </span>
                                </a>
                            </li>
                        )}
                    </ul>
                    <a
                        className="Checkout-btn--LBC Checkout-btn"
                        href="http://www.leboncoin.fr/url"
                        title="Page Leboncoin du Truc'shop"
                    >
                        Visiter notre page sur <em>leboncoin.fr</em>
                    </a>
                </>}
            </div>
        </div>
    );
}

export default Checkout;
