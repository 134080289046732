export const priceNumberToLabel = number =>
    `${number.toString().replace('.',',')}€`;

export const isMoreInStock = product =>
    !product.stock || (
        product.stock && product.stock > product.quantity
    );

export const getTotal = cart => {
    const initialValue = 0;
    return cart.reduce(
        (accumulator, product) => {
            const price = product.price
                ? product.quantity * product.price
                : 0;
            return accumulator + price;
        },
        initialValue,
    );
}